
import * as aroraProviderRuntime$_3oPv89a1xpFCDA15mx3GeOB9b06rVi4yG0zR29W3bQc from 'D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/build-rest-4a45c0ec-2db8-4fde-82e1-b24b4077c7da/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$_3oPv89a1xpFCDA15mx3GeOB9b06rVi4yG0zR29W3bQc, defaults: {} }
}
        